const images = [
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/1.jpg',
    url:
    'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/2.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/3.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/4.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/5.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/6.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/7.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/8.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/9.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/10.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/11.jpg',
      url:
      'https://lorco.fr'
  },
  {
    image:
      'https://lorco.fr/wp-content/reactpress/apps/aaa/build/img/12.jpg',
    url:
    'https://lorco.fr'
  }
]

export default images
